import { Meta } from "@solidjs/meta";

interface DescriptionProps {
  readonly children?: string;
}

export default function Description(props: DescriptionProps) {
  return (
    <>
      <Meta name="description" content={props.children} />
      <Meta property="og:description" content={props.children} />
    </>
  );
}
